import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import Loader from "../../shared/components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { updateShop } from "../../services/shopService";
import { toastMessage } from "../../shared/components/Toast";
import { openHours } from "./constant";
function Index() {
  const location = useLocation();
  const shopDetail = location?.state?.shop;

  const navigate = useNavigate();
  const [invalidHour, setInvalidHour] = useState(false);
  const [phoneNum, setPhoneNum] = useState(
    shopDetail?.phone ? shopDetail?.phone : ""
  );
  const [phoneNums, setPhoneNums] = useState(
    shopDetail?.phones || [] // Initialize as an array
  );
  const [email, setEmail] = useState(
    shopDetail?.email ? shopDetail?.email : ""
  );
  const [loading, setLoading] = useState(false);
  const [GoogleLink, setGoogleLink] = useState(
    shopDetail?.googleMapsLink ? shopDetail?.googleMapsLink : ""
  );
  const [imageLink, setImageLink] = useState(
    shopDetail?.image ? shopDetail?.image : ""
  );
  const [deletedPhones, setDeletedPhones] = useState([]);
  const [openinHour, setOpeningHour] = useState(
    location?.state?.shop?.openingHours &&
      location?.state?.shop?.openingHours?.length
      ? location?.state?.shop?.openingHours
      : openHours
  );

  useEffect(() => {
    // Check if any row has one empty field between from and till
    const hasInvalidHour = openinHour.some((hour) => {
      return (
        ((hour.from === "" || hour.from.includes("__:__")) &&
          (hour.till !== "" || hour.till.includes("__:__"))) ||
        ((hour.till === "" || hour.from.includes("__:__")) &&
          (hour.from !== "" || hour.from.includes("__:__")))
      );
    });

    // Update the invalidHour state
    setInvalidHour(hasInvalidHour);
  }, [openinHour]);

  const hourValidator = (hour, hourWindow) => {
    const isFromEmpty = hourWindow === "from" && hour.from === "";
    const isTillEmpty = hourWindow === "till" && hour.till === "";

    return (
      (isFromEmpty && hour.till !== "" && !hour.till.includes("__:__ ")) ||
      (isTillEmpty && hour.from !== "" && !hour.from.includes("__:__ "))
    );
  };

  const handleChange = (value, inx, type) => {
    let cloneHour = [...openinHour];
    if (type === "from") {
      if (value) {
        cloneHour[inx].from = value.replace("-", ":") + " am";
      } else {
        cloneHour[inx].from = "";
      }
    } else {
      if (value) {
        cloneHour[inx].till = value.replace("-", ":") + " pm";
      } else {
        cloneHour[inx].till = "";
      }
    }
    setOpeningHour(cloneHour);
  };
  const handleSubmit = () => {
    if (!invalidHour) {
      let obj = {
        phones: phoneNums,
        openingHours: openinHour,
        googleMapsLink: GoogleLink,
        image: imageLink,
        email: email,
        phonesToDelete: deletedPhones,
      };
      setLoading(true);
      updateShop(shopDetail?._id, obj)
        .then(({ data }) => {
          navigate("/shop");
          toastMessage("Shop Updated Successfully!", "success");
        })
        .catch((err) => {
          toastMessage(err?.response?.data?.message, "error");
        })
        .finally(() => setLoading(false));
    }
  };

  const getDayLabel = (index) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[index];
  };

  return (
    <>
      <div>Update Shop</div>
      <Typography sx={{ fontSize: "14px" }} color={"#899CAF"}>
        you can edit google map only in this section.Goto tekmetrik for further
        shop modifications
      </Typography>
      <Box display={"flex"} justifyContent={"center"}>
        <Box sx={{ mt: 5, pb: 5 }} border={"1px solid #B9C5D275"}>
          <Box bgcolor={"#F8F8F8"} sx={{ py: 2 }}>
            <Typography variant="body5" sx={{ ml: 2 }} color={"#899CAF"}>
              Shop Details
            </Typography>
          </Box>
          {/*-------------------- input ----------------------*/}
          <Box sx={{ px: 3 }}>
            <Grid container spacing={0}>
              <Grid sx={4}>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body5"
                    fontFamily={"inter"}
                    fontSize={"16px"}
                    fontWeight={500}
                    color={"#899CAF"}
                    sx={{ mb: 0 }}
                    id="starick"
                  >
                    Operational Hours
                  </Typography>
                  {openinHour?.map((hour, inx) => {
                    return inx < 6 ? (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: { sm: "1fr 1fr" },
                          mb: 2, // Add margin to separate each row
                          mt: 2,
                        }}
                        key={inx}
                      >
                        <Typography
                          variant="body5"
                          fontSize={"12px"}
                          sx={{
                            color: "#899CAF",
                            mb: 1,
                            gridColumn: "1 / span 2",
                          }}
                        >
                          {getDayLabel(inx)}
                        </Typography>
                        <Box
                          sx={{
                            p: 1,
                            m: 1,
                            border: `1px solid ${
                              hourValidator(hour, "from") ? "red" : "#899CAF"
                            }`,
                            borderRadius: "5px",
                          }}
                        >
                          <InputHour
                            value={hour?.from}
                            change={(e) =>
                              handleChange(e.target.value, inx, "from")
                            }
                            timeType={"am"}
                            placeholder={"From"}
                          />
                        </Box>
                        <Box
                          sx={{
                            p: 1,
                            m: 1,
                            border: `1px solid ${
                              hourValidator(hour, "till") ? "red" : "#899CAF"
                            }`,
                            borderRadius: "5px",
                          }}
                        >
                          <InputHour
                            value={hour?.till}
                            change={(e) =>
                              handleChange(e.target.value, inx, "till")
                            }
                            timeType={"pm"}
                            placeholder={"Till"}
                          />
                        </Box>
                      </Box>
                    ) : null;
                  })}
                </Box>
              </Grid>
              <Grid
                sx={8}
                style={{
                  width: "78%",
                  paddingTop: "40px",
                  paddingLeft: "15px",
                }}
                component="form"
              >
                {phoneNums.map((phone, index) => (
                  <Grid
                    item
                    xs={12}
                    key={phone._id || index}
                    display="flex"
                    alignItems="center"
                  >
                    <CustomInput
                      title={`Phone Number ${index + 1}`}
                      placeholder="Enter Phone Number"
                      value={phone.number} // Display only the number
                      change={(e) => {
                        const updatedPhones = [...phoneNums];
                        updatedPhones[index].number = e.target.value;
                        setPhoneNums(updatedPhones);
                      }}
                    />
                    <IconButton
                      color="error"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        const updatedPhones = phoneNums.filter(
                          (_, i) => i !== index
                        );
                        setPhoneNums(updatedPhones);

                        if (phone._id) {
                          setDeletedPhones([...deletedPhones, phone._id]);
                        }
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                ))}

                <Grid item xs={12} sx={{ mt: 3 }}>
                  {" "}
                  {/* Added more space */}
                  <Button
                    sx={{ mt: 2 }}
                    variant="outlined"
                    onClick={() => setPhoneNums([...phoneNums, { number: "" }])}
                  >
                    Add New Phone Number
                  </Button>
                </Grid>

                <Grid item xs={12} sx={{ mt: 10 }}>
                  {" "}
                  {/* Added more space */}
                  <CustomInput
                    title={"Email address"}
                    placeholder="Enter Email"
                    value={email}
                    change={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid xs={12}>
                  <CustomInput
                    title={"Google map link"}
                    placeholder={"Google map link"}
                    value={GoogleLink}
                    change={(e) => setGoogleLink(e.target.value)}
                  />
                </Grid>
                <Grid xs={12}>
                  <CustomInput
                    title={"Shop Image"}
                    placeholder={"Enter Shop Image"}
                    value={imageLink}
                    change={(e) => setImageLink(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"end"}
        sx={{ mr: 4.5, mt: 4, pb: 5 }}
      >
        <Box>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              color: "#8B8D90",
              bgcolor: "#EEEEEE",
              px: 6,
              py: 1.5,
              mr: 2.5,
            }}
          >
            Back
          </Button>
        </Box>
        <Box>
          <Button
            disabled={loading || invalidHour}
            sx={{
              color: "#2E7FFD",
              bgcolor: "#2E7FFD1F",
              px: 6,
              py: 1.5,
            }}
            onClick={handleSubmit}
          >
            {loading ? <Loader colors={"#2278CF"} /> : "Update"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
const InputHour = ({ value, placeholder, change, timeType }) => {
  return (
    <>
      <div className="flex">
        <InputMask
          placeholder={placeholder}
          className="shopUpdateMask"
          mask="99-99"
          value={value}
          onChange={change}
        />
        <label className="shopUpdateInput">{timeType}</label>
      </div>
    </>
  );
};
const CustomInput = ({ title, value, change, placeholder }) => {
  return (
    <>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { mt: 0 },
          "& .MuiInputBase-root": {
            width: "100% !important",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography
          variant="body5"
          fontFamily={"inter"}
          fontSize={"12px"}
          fontWeight={500}
          color={"#899CAF"}
          sx={{ ml: 0.7 }}
          id="starick"
        >
          {title}
        </Typography>
        <TextField
          name="offer_tital"
          sx={{
            width: "100%",
          }}
          placeholder={placeholder}
          value={value}
          onChange={change}
        />
      </Box>
    </>
  );
};

export default Index;
