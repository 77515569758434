import { HTTP_Request } from "../utils/interceptor";
import { BASE_URL } from "../utils/constant";
import { Endpoints } from "./endpoint";
import axios from "axios";

const listAllShop = () => {
  
  return HTTP_Request.get(BASE_URL + Endpoints.shop.list);
}
const listAllShopWithData = () => {
  
  return HTTP_Request.get(BASE_URL + Endpoints.shopwithphone.list);
}
const updateShop = (shopId,obj) => {
  
  return HTTP_Request.patch(BASE_URL + Endpoints.shop.updateShop+shopId,obj);
}


export { listAllShop,listAllShopWithData,updateShop};
